<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 mb-4">
				<h3 class="m-0 qa-legal-company-title">
					{{ SHARED.LegalCompany.Title }}
				</h3>
			</div>
		</div>

		<div v-if="hasLegalCompany" class="row">
			<div
				v-for="(item, index) in legalCompanyList"
				:key="`legalCompany-accordion-${index}`"
				class="col-12 d-flex mb-4"
			>
				<recess-accordion
					variant="variant-1"
					:class="`s-height-auto w-100 qa-legal-company-accordion-${index}`"
					:active="item.isActive"
				>
					<template slot="accordion-title">
						<div class="py-2 d-flex flex-column justify-content-between">
							<h5 class="qa-legal-company-name-column mb-2">
								{{ item.name || "-" }}
							</h5>
							<p
								v-if="hasOverallStatus(item)"
								:class="`mr-3 qa-legal-company-overall-status-${index}`"
							>
								{{ showLegalCompanyStatus(item) }}
								<a
									:class="`qa-legal-company-merchantOnboardingUrl-${index}`"
									v-if="!hasValidStatus(item) && item.merchantOnboardingUrl && !isAccountManager"
									:href="item.merchantOnboardingUrl"
									><br />
									{{ SHARED.LegalCompany.Messages.Error.OPPLink }}</a
								>
							</p>
						</div>
					</template>

					<template v-slot:accordionRightCustomActions>
						<span
							:class="`mt-1 mr-3 c-icon c-icon__bin qa-remove-legal-company-item-button-${index}`"
							@click="confirmLegalCompanyDeletion(item.id, index)"
							v-if="!isAccountManager"
						></span>
					</template>

					<div slot="accordion-content" data-test="legal-company-item">
						<div class="row mt-0">
							<div class="col-12">
								
								<div
									:data-test="`legal-company-item-status-${index}`"
									v-if="!hasValidStatus(item)"
									class="d-flex flex-column py-4"
								>
									<span>{{ SHARED.LegalCompany.ErrorStatusList }}</span>
									<ul>
										<li
											:class="`ml-4 qa-legal-company-item-overall-status-${index}`"
											v-if="!hasValidOverallStatus(item)"
										>
											{{ SHARED.LegalCompany.OverallStatus.Label }} :
											{{ item.overallStatusDisplayValue || "-" }}
										</li>
										<li
										:class="`ml-4 qa-legal-company-item-contact-status-${index}`"
										v-if="!hasValidContactVerificationStatus(item)"
										>
										{{ SHARED.LegalCompany.ContactVerificationStatus.Label }} :
										{{ item.contactVerificationStatusDisplayValue|| "-" }}
										</li>
										<li
											:class="`ml-4 qa-legal-company-item-compliance-status-${index}`"
											v-if="!hasValidComplianceStatus(item)"
										>
											{{ SHARED.LegalCompany.ComplianceStatus.Label }}
											: {{ item.complianceStatusDisplayValue || "-" }}
										</li>
										<li
											:class="`ml-4 qa-legal-company-item-bank-account-status-${index}`"
											v-if="!hasValidBankAccountVerificationStatus(item)"
										>
											{{ SHARED.LegalCompany.BankAccountVerificationStatus.Label }} :
											{{item.bankAccountVerificationStatusDisplayValue || "-" }}
										</li>
										<li
											:class="`ml-4 qa-legal-company-item-oppubo-status-${index}`"
											v-if="!hasValidOppUboStatus(item)"
										>
											{{ SHARED.LegalCompany.OppUboStatus.Label }} :
											{{ item.oppUboStatusDisplayValue || "-" }}
										</li>
									</ul>
								</div>

								<financial-information-form
									:ref="`financialInformationForm-${index}`"
									:financial-existing-names="legalCompanyList"
									:financial-information="item"
									:financial-information-index="index"
									:is-account-manager="isAccountManager"
									:legal-company-mode="legalCompanyMode"
								/>
								<address-form
									:ref="`addressForm-${index}`"
									:address-input="item"
									:address-index="index"
									:address-required="true"
									:isAccountManager="isAccountManager"
								/>
							</div>
							<div class="col-12 mt-3 d-flex justify-content-end">
								<recess-button
									v-if="!hasOverallStatus(item) && isAdministrator"
									:key="`legalCompany-accordion-call-registratio-${index}`"
									:title="SHARED.LegalCompany.CallRegistrationButton.Title"
									:class="`qa-call-registration-${index}`"
									variant="secondary"
									@click.native.prevent="onCallRegistrationHandler(index)"
								/>
								<recess-button
								   	v-if="!isAccountManager"
									:key="`legalCompany-accordion-save-${index}`"
									:title="BUTTON_TEXT.save"
									variant="secondary"
									class="ml-2 qa-edit-single-provider-legal-company-button"
									@click.native.prevent="onSaveSingleHandler(item, index)"
								/>
							</div>
						</div>
					</div>
				</recess-accordion>
			</div>
		</div>

		<!-- show placeholder if there is no saved legal company -->
		<div v-else class="row">
			<div class="col-12">
				<h4>{{ SHARED.LegalCompany.NoSavedLegalCompany }}</h4>
			</div>
		</div>

		<!-- Add legal company button -->
		<div class="row" v-if="!isAccountManager">
			<div class="col-12 d-flex justify-content-end u-flex-reset">
				<recess-button
					v-if="showAddLegalCompanyButton"
					:title="BUTTON_TEXT.add"
					icon="add"
					variant="variant4"
					class="d-flex qa-add-legal-company-item-button"
					@click.native.prevent="addItem"
				/>
			</div>
		</div>

		<delete-legal-company-popup
			@deleteTriggered="updateLegalCompanyListAfterDelete"
			:is-modal-visible="isDeleteLegalCompanyPopupVisible"
			:legal-company-id-to-delete="legalCompanyIdToDelete"
			:api-base-url="apiBaseUrl"
			@close="closeDeleteLegalCompanyPopup"
		/>

		<warning-kvk-popup
			:is-modal-visible="isWarningPopupVisible"
			@close="closeWarningKvkPopup"
			@confirmTriggered="createOrUpdateLegalCompany(
				legalCompanyList[legalCompanyToBeAddedSaved],
				originalLegalCompanyList,
				propertiesToPatch
			)"
		>
	
		<template>
			<div v-for="(legalCompany, index) in legalCompaniesWithSameKvk" :key="index">
				<p v-if="legalCompany.providerName"> 
					{{SHARED.LegalCompany.Popup.Warning.Provider}} <strong>{{legalCompany.providerName}}</strong>, {{SHARED.LegalCompany.Popup.Warning.LegalCompany}} <strong>{{legalCompany.name}}</strong>.
				</p> 
				<p v-else>
					{{SHARED.LegalCompany.Popup.Warning.Account}} <strong>{{legalCompany.accountName}}</strong>, {{SHARED.LegalCompany.Popup.Warning.LegalCompany}} <strong>{{legalCompany.name}}</strong> .
				</p>
			</div>
		</template> 
	
		</warning-kvk-popup>
	</div>
</template>

<script>
import Vue from "vue";

import UserService from "../../../shared/services/UserService";
import { getItems, getItemById, patchItem, postItem} from "../../api/SharedClient";
import { API_CALL_URL_PATHS, BUTTON_TEXT, } from "../../constants/constantsGeneral.json";
import { SHARED } from "../../constants/EdumsSharedConstants.json";
import { btwMaxCharactersError, defaultErrorMessage, legalCompanyRequiredEmailError, legalCompanySuccessAction, 
	legalCompanyUniqueEmailError, kycSuccessRequest, oppActionMessage, requiredError } from '../../../shared/constants/validationMessageHelper'

const AddressForm = () => import("@/../../shared/components/Shared/AddressForm");
const FinancialInformationForm = () => import("@/../../shared/components/Shared/FinancialInformationForm");
const DeleteLegalCompanyPopup = () => import("@/../../shared/components/LegalCompany/DeleteLegalCompanyPopup");
const WarningKvkPopup = () => import("../WarningKvkPopup/WarningKvkPopup.vue")

export default {
	name: "LegalCompany",
	props: {
		isAccountManager: {
			type: Boolean,
			default: false
		},
		legalCompanyMode: {
			type: String,
			default: null
		}
	},
	components: {
		AddressForm,
		FinancialInformationForm,
		DeleteLegalCompanyPopup,
		WarningKvkPopup
	},
	data() {
		return {
			SHARED,
			userService: new UserService(),
			API_CALL_URL_PATHS,
			BUTTON_TEXT,
			legalCompanyList: [],
			isDeleteLegalCompanyPopupVisible: false,
			originalLegalCompanyList: [],
			propertiesToPatch: [
				"name",
				"vatNumber",
				"kvk",
				"iban",
				"bicSwift",
				"creditNumber",
				"debtorNumber",
				"street",
				"number",
				"numberAdditive",
				"postalCode",
				"city",
				"countryCode",
				"emailAddress",
			],
			providerId: null,
			isAdministrator: null,
			legalCompanyIdToDelete: null,
			apiBaseUrl: null,
			legalCompanyFilter: null,
			resourceId: null,
			legalCompaniesWithSameKvk: [],
			legalCompanyToBeAddedSaved: null,
			isWarningPopupVisible: false
		};
	},
	computed: { 
		hasLegalCompany() {
			return this.legalCompanyList && this.legalCompanyList.length > 0;
		},
		showAddLegalCompanyButton() {
			return this.legalCompanyMode !== 'Account' || (this.legalCompanyMode === 'Account' && this.legalCompanyList.length === 0)
		}
	},
	async created() {
		await this.setLegalCompanyConfig();
	},
	async mounted() {
		this.isAdministrator = await this.userService.isAdministrator();

		this.$nextTick(() => {
			
			this.getLegalCompanyList();
			this.$bus.on("addressItem", this.setAddressItem); 
			
		});
	},
	beforeDestroy() {
		this.$bus.off("addressItem");
	},
	methods: {
		async setLegalCompanyConfig() { 
			// Account
			if(this.legalCompanyMode === 'Account') {
				this.resourceId = this.$route.params.id || (await this.userService.getUserAccountId()); 
				
				this.apiBaseUrl = process.env.VUE_APP_ACCOUNT_API_URL 
				this.legalCompanyFilter = `accountId eq ${this.resourceId} and deleted eq false`
			} 
			
			// Provider
			if(this.legalCompanyMode === 'Provider') {
				this.resourceId = this.$route.params.id || (await this.userService.getUserProviderId()); 

				this.apiBaseUrl = process.env.VUE_APP_PROVIDER_API_URL
				this.legalCompanyFilter = `providerId eq ${this.resourceId} and deleted eq false` 
			}
		},
		confirmLegalCompanyDeletion(legalCompanyId, index) {
			if (!legalCompanyId) {
				this.legalCompanyList.splice(index);
				return;
			}
			this.isDeleteLegalCompanyPopupVisible = true;
			this.legalCompanyIdToDelete = legalCompanyId;
		},
		closeDeleteLegalCompanyPopup() {
			this.isDeleteLegalCompanyPopupVisible = false;
		},
		closeWarningKvkPopup() {
			this.isWarningPopupVisible = false;
		},
		updateLegalCompanyListAfterDelete(removedItemId) {
			this.legalCompanyList = this.legalCompanyList.filter(
				(item) => item.id !== removedItemId
			);
			this.originalLegalCompanyList = JSON.parse(
				JSON.stringify(this.legalCompanyList)
			);
		},
		async getLegalCompaniesDetails(items) {
			if (items && items.length > 0) {
				await items.reduce(async (acc, legalCompany) => {
					this.legalCompanyList.push(
						await this.getLegalCompanyDetail(legalCompany.id)
					);
					return acc;
				}, []);
			}
		},
		async getLegalCompanyDetail(legalCompanyId) {
			try {
				return await getItemById(
					this.apiBaseUrl,
					API_CALL_URL_PATHS.legalCompanies,
					legalCompanyId,
					null,
					false
				);
			} catch (error) {
				console.error(
					"Something went wrong while retrieving provider legal company item",
					error
				);
			}
		},
		addItem() {
			const emptyLegalCompanyItem = {
				name: null,
				vatNumber: null,
				kvk: null,
				iban: null,
				bicSwift: null,
				creditNumber: null,
				debtorNumber: null,
				street: null,
				number: null,
				numberAdditive: null,
				postalCode: null,
				city: null,
				countryCode: null,
				emailAddress: null,
				isActive: true,
			};

			// only allow to add more than 1 row if user filled in at least one value
			const checkEmptyItems = this.legalCompanyList.filter(
				(item) => item.name === null || item.name === ""
			);
			if (checkEmptyItems.length >= 1 && checkEmptyItems.length > 0) return;
			this.legalCompanyList.push(emptyLegalCompanyItem);
		},
		async onSaveSingleHandler(item, index) {
			this.$refs[`addressForm-${index}`][0].$v.$touch();
			this.$refs[`financialInformationForm-${index}`][0].$v.$touch();

			if (
				!this.$refs[`addressForm-${index}`][0].$v.$invalid &&
				!this.$refs[`financialInformationForm-${index}`][0].$v.$invalid
			) {
				// Clearing the emailAddress field requires a NULL value,
				// Because the database will mark empty strings as duplicates
				if (
					this.legalCompanyList[index].emailAddress !== null &&
					!this.legalCompanyList[index].emailAddress.replace(/\s/g, "").length
				) {
					this.legalCompanyList[index].emailAddress = null;
				}

				await this.getAllLegalCompaniesWithSameKvk(item)

				// If result has no result it means no kvk with the same number exists
				if(this.legalCompaniesWithSameKvk.length === 0) {
					this.createOrUpdateLegalCompany(
						this.legalCompanyList[index],
						this.originalLegalCompanyList,
						this.propertiesToPatch
					);
					return
				} 
				
				this.legalCompanyToBeAddedSaved = index
				
				//NOTE: Do not replace this with a forEach since the forEach method 
				//only accepts synchronous functions, and therefore is NOT compatible with the async/await syntax
				for(const legalCompany of this.legalCompaniesWithSameKvk) {
					if (this.legalCompanyMode === 'Provider') {
						legalCompany.providerName = await this.getProviderName(legalCompany.providerId)
					}

					if (this.legalCompanyMode === 'Account') {
						legalCompany.accountName = await this.getAccountName(legalCompany.accountId)
					}
				}

				this.isWarningPopupVisible = true
			}
		},
		async getProviderName(providerId) {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL, 
					`${API_CALL_URL_PATHS.providers}/${providerId}`,
				)

                if (!response) return
                return response.name
 
            } catch (error) {
                console.error('Something went wrong while retrieving providers', error)
            }
		},
		async getAccountName(accountId) {
			try {
				const response = await getItems(
					process.env.VUE_APP_ACCOUNT_API_URL, 
					`${API_CALL_URL_PATHS.accounts}/${accountId}`,
				)

                if (!response) return
                return response.name
 
            } catch (error) {
                console.error('Something went wrong while retrieving accounts', error)
            }
		},
		async onCallRegistrationHandler(index) {
			this.$refs[`addressForm-${index}`][0].$v.$touch();
			this.$refs[`financialInformationForm-${index}`][0].$v.$touch();

			if (
				!this.$refs[`addressForm-${index}`][0].$v.$invalid &&
				!this.$refs[`financialInformationForm-${index}`][0].$v.$invalid
			) {
				await this.postMerchantRegistration(this.legalCompanyList[index].id);
			}
		},
		showLegalCompanyStatus(legalCompany) {
			return this.hasValidStatus(legalCompany)
				? oppActionMessage()
				: oppActionMessage(true);
		},
		hasValidOverallStatus(legalCompany) {
			return !legalCompany.overallStatus || legalCompany.overallStatus === this.SHARED.LegalCompany.Status.Live
		},
		hasValidComplianceStatus(legalCompany) {
			return !legalCompany.complianceStatus || legalCompany.complianceStatus === this.SHARED.LegalCompany.Status.Verified
		},
		hasValidContactVerificationStatus(legalCompany) {
			return !legalCompany.contactVerificationStatus || legalCompany.contactVerificationStatus === this.SHARED.LegalCompany.Status.Verified
		},
		hasValidBankAccountVerificationStatus(legalCompany) {
			return !legalCompany.bankAccountVerificationStatus || legalCompany.bankAccountVerificationStatus === this.SHARED.LegalCompany.Status.Approved
		},
		hasValidOppUboStatus(legalCompany) {
			return !legalCompany.oppUboStatus || legalCompany.oppUboStatus === this.SHARED.LegalCompany.Status.Verified
		},
		hasValidStatus(legalCompany) {
			return (
				this.hasValidOverallStatus(legalCompany) &&
				this.hasValidComplianceStatus(legalCompany) &&
				this.hasValidContactVerificationStatus(legalCompany) &&
				this.hasValidBankAccountVerificationStatus(legalCompany) &&
				this.hasValidOppUboStatus(legalCompany)
			);
		},
		hasOverallStatus(legalCompany) {
			return legalCompany.overallStatus;
		},
		async postMerchantRegistration(legalCompanyId) {
			await postItem(
				this.apiBaseUrl,
				`${API_CALL_URL_PATHS.legalCompanies}/${legalCompanyId}/${API_CALL_URL_PATHS.merchantRegistration}`,
				null,
				false
			)
				.then(() => {
					const toastNotification = {
						type: "success",
						message: kycSuccessRequest
					};

					this.$store.dispatch(
						"toastNotificationModule/add",
						toastNotification,
						{
							root: true,
						}
					);
				})
				.catch((error) => {
					this.getNotificationError(error, legalCompanyId);
					console.error(
						"Something went wrong while posting provider legal company item",
						error
					);
				});
		},
		createOrUpdateLegalCompany(legalCompany, originalArray, keys) {
			if (legalCompany.id) {
				const dataToBePatched = [];
				keys.forEach((key) => {
					this.isValueUpdatedAndSetDataToBePatched(
						originalArray,
						legalCompany,
						key,
						dataToBePatched
					);
				});
				this.patchLegalCompanyItem(
					legalCompany.id,
					dataToBePatched,
					legalCompany.name
				);
			} else {
				let objectToPost = {
					...legalCompany,
					providerId: null,
					accountId: null
				};

				if(this.legalCompanyMode === 'Provider') {
					objectToPost.providerId = this.resourceId
				}

				if(this.legalCompanyMode === 'Account') {
					objectToPost.accountId = this.resourceId
				}

				const isDuplicatedObject =
					originalArray.some(
						(originalObj) => originalObj.name === objectToPost.name
					) &&
					originalArray.some(
						(originalObj) => originalObj.vatNumber === objectToPost.vatNumber
					);
				if (!isDuplicatedObject) {
					this.postLegalCompanyItem(objectToPost);
				}
			}

			this.closeWarningKvkPopup()
		},
		isValueUpdatedAndSetDataToBePatched(
			originalArray,
			updatedObj,
			key,
			dataToBePatched
		) {
			const isPropertyUpdated = originalArray.some(
				(originalObj) =>
					originalObj.id === updatedObj.id &&
					originalObj[key] !== updatedObj[key]
			);
			if (isPropertyUpdated) {
				dataToBePatched.push({
					value: updatedObj[key],
					path: key,
					op: "replace",
				});
			}
		},
		getNotificationError(error, legalCompanyName) {
			const toastNotification = {
				type: "error",
				message: null,
			};

			const errorData = error && error.response && error.response.data;

			if (
				errorData &&
				errorData.error &&
				errorData.error.message === "Name and ProviderId should be unique." &&
				errorData.error.description === "unique_group"
			) {
				toastNotification.message = `Dit ${legalCompanyName} ${uniqueError('De combinatie van handelsnaam en provider id')}`;
			} else if (
				errorData &&
				errorData.error &&
				errorData.error.message === "EmailAddress should be unique." &&
				errorData.error.description === "unique_field"
			) {
				toastNotification.message = legalCompanyUniqueEmailError;
			}  else if (
				errorData &&
				errorData.error &&
				errorData.error.message === "CreditNumber is required." &&
				errorData.error.description === "wrong_format"
			) {
				toastNotification.message = requiredError(SHARED.FinancialInformation.CreditNumber.Label);
			}else if (
				errorData &&
				errorData.error &&
				errorData.error.message === "An e-mail address is required to register as a merchant." &&
				errorData.error.description === "not_supported"
			) {
				toastNotification.message = legalCompanyRequiredEmailError;
			} else if (
				errorData &&
				errorData.error &&
				errorData.error.message === "VatNumber cannot exceed 50 characters." &&
				errorData.error.description === "wrong_format"
			) {
				toastNotification.message = btwMaxCharactersError;
			} else if (
				errorData &&
				errorData.error &&
				errorData.error.message === "AccountId should be unique." &&
				errorData.error.description === "unique_field"
			) {
				toastNotification.message = `Unique account id`;
			}
			else {
				toastNotification.message = defaultErrorMessage;
			}

			this.$store.dispatch("toastNotificationModule/add", toastNotification, {
				root: true,
			});
		},
		async postLegalCompanyItem(newLegalCompany) {
			await postItem(
				this.apiBaseUrl,
				API_CALL_URL_PATHS.legalCompanies,
				newLegalCompany,
				false
			)
				.then((response) => {
					if (!response) return;

					const toastNotification = {
						type: "success",
						message: legalCompanySuccessAction('aangemaakt')
					};

					this.$store.dispatch(
						"toastNotificationModule/add",
						toastNotification,
						{
							root: true,
						}
					);

					const indexOfPostedObj = this.legalCompanyList.findIndex((x) => x.name === response.name);
					Vue.set(this.legalCompanyList, indexOfPostedObj, response);
					this.originalLegalCompanyList = JSON.parse(JSON.stringify(this.legalCompanyList));
				})
				.catch((error) => {
					this.getNotificationError(error, newLegalCompany.name);
					console.error("Something went wrong while posting provider legal company item",error);
				});
		},
		async patchLegalCompanyItem(
			legalCompanyId,
			dataToBePatched,
			legalCompanyName
		) {
			if (dataToBePatched.length > 0) {
				await patchItem(
					this.apiBaseUrl,
					API_CALL_URL_PATHS.legalCompanies,
					legalCompanyId,
					dataToBePatched,
					false
				)
					.then((response) => {
						if (!response) return;

						const toastNotification = {
							type: "success",
							message: legalCompanySuccessAction('bewerkt')
						};

						this.$store.dispatch("toastNotificationModule/add",toastNotification, {
							root: true,
						});

						const indexOfPatchedObj = this.originalLegalCompanyList.findIndex(
							(x) => x.id === response.id
						);

						Vue.set(this.legalCompanyList, indexOfPatchedObj, response);
						this.originalLegalCompanyList = JSON.parse(
							JSON.stringify(this.legalCompanyList)
						);
					})
					.catch((error) => {
						this.getNotificationError(error, legalCompanyName);
						console.error(
							"Something went wrong while patching provider legal company item",
							error
						);
					});
			}
		},
		async getAllLegalCompaniesWithSameKvk(legalCompany) {
			const filter = `kvk eq '${legalCompany.kvk}' and deleted eq false`
			const response = await getItems(
				this.apiBaseUrl,
				API_CALL_URL_PATHS.legalCompanies,
				1,
				99999, // get all legal companies
				null,
				filter,
				false
			);
			try {
				if (!response) return;
				this.legalCompaniesWithSameKvk = response.items.filter(item => item.id !== legalCompany.id)

			} catch (error) {
				console.error(
					"Something went wrong while retrieving provider legal company list",
					error
				);
			}
		},
		async getLegalCompanyList() {
			const response = await getItems(
				this.apiBaseUrl,
				API_CALL_URL_PATHS.legalCompanies,
				1,
				99999, // get all legal companies
				null,
				this.legalCompanyFilter,
				false
			);
			try {
				if (!response) return;
				// make a copy of the original legal company list which the values will not be
				// changed when legal company list's values changed
				// we only patch legal company item if its value have changed
				await this.getLegalCompaniesDetails(response.items);
				this.originalLegalCompanyList = JSON.parse(
					JSON.stringify(this.legalCompanyList)
				);
			} catch (error) {
				console.error(
					"Something went wrong while retrieving provider legal company list",
					error
				);
			}
		},
		setAddressItem({ addressIndex, address }) {
			this.legalCompanyList[addressIndex].street = address.street;
			this.legalCompanyList[addressIndex].number = address.number;
			this.legalCompanyList[addressIndex].numberAdditive = address.numberAdditive;
			this.legalCompanyList[addressIndex].postalCode = address.postalCode;
			this.legalCompanyList[addressIndex].city = address.city;
			this.legalCompanyList[addressIndex].countryCode = address.countryCode;
		},
	},
};
</script>
